import React from 'react';
import { LayoutBlank } from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import AuthWrapper from '../../components/layout/AuthWrapper';
import FillEnquiry from '../../components/organisms/Form/FIllEnquiry';

const EnquiryFormPage = () => {
  return (
    <AuthWrapper userRole="all">
      <Head title="Learner Form" />
      <LayoutBlank>
        <FillEnquiry userType="enquiry" />
      </LayoutBlank>
    </AuthWrapper>
  );
};

export default EnquiryFormPage;
