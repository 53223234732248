import React, { useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Checkbox from '../../atoms/form-fields/checkbox/Checkbox';
import Stack from '../../atoms/stack/Stack';

interface IConfirmSubmitForm {
  onComplete: () => void;
}

const ConfirmSubmitForm = ({ onComplete }: IConfirmSubmitForm) => {
  const closeModal = useCloseModal();
  const [confirm, setConfirm] = useState(false);
  return (
    <Stack stackMultiplier={2}>
      <h2>Submit form</h2>
      <Checkbox
        id="confirm-form-submit"
        name="confirm-form-submit"
        label="I confirm all of the information in the form is correct and cannot be changed once sent."
        inline
        checked={confirm}
        onChange={() => {
          ReactGA.event({
            category: 'Checkbox',
            action: 'Submit form',
          });
          setConfirm(!confirm);
        }}
        dataQa="submitForm-chk-confirm"
      />
      <ButtonRowMobile>
        <Button
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Submit form modal - Cancel',
            });
            closeModal();
          }}
          variant="secondary"
          dataQa="submitForm-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Submit form modal - Submit',
            });
            onComplete();
            closeModal();
          }}
          disabled={!confirm}
          dataQa="submitForm-btn-submit"
        >
          Submit
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};

export default ConfirmSubmitForm;
