import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { IFormTemplate } from '../../../context-providers/forms/forms-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { useDetectWindowClose } from '../../../utils/browser';
import { fetchAPI, logout } from '../../../utils/fetch-api';
import { getQueryVar } from '../../../utils/query-vars';
import Button from '../../atoms/button/Button';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import FixedFooter from '../../atoms/fixed-footer/FixedFooter';
import Message from '../../atoms/message/Message';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';
import { ContentContainer, LightContentBox } from '../../layout/Layout.style';
import { FormTitleHeader } from './Form.style';
import RenderFormTemplate from './RenderFormTemplate';
import { isDataTable } from './FormField';
import { TextInputCell } from '../../modals/data-tables/cell-models/TextInputCell';
import ConfirmSubmitForm from '../../modals/forms/ConfirmSubmitForm';

const isBrowser = typeof window !== 'undefined';

const FillEnquiry = () => {
  useDetectWindowClose();
  const showModal = useShowModal();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState<IFormTemplate | null>(null);

  // load form
  const formId = getQueryVar('fid') || '';
  if (formId === '' && isBrowser) {
    logout();
  }

  const getFieldValue = (fieldName: string) => {
    return form?.formData.find((x) => x.inputType === fieldName)?.props.value;
  };

  const load = useCallback(async () => {
    const res = await fetchAPI<IFormTemplate>({
      path: `v2/forms/view/enquiry/${formId}`,
      redirectNotFound: true,
    });
    if (res.success && typeof res.body !== 'string') {
      setForm(res.body || null);
    }
  }, [formId]);

  useEffect(() => {
    load();
  }, [load]);

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    submitForm();
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    const res = await fetchAPI<{ id: string }>({
      path: `enquiries/submit`,
      body: {
        firstName: getFieldValue('FirstName'),
        lastName: getFieldValue('Surname'),
        emailAddress: getFieldValue('EmailAddress'),
        formTemplate: form,
        formTemplateId: formId,
      },
      method: 'POST',
      responseType: 'text',
    });
    setIsSubmitting(false);

    if (res.success) {
      setSubmitted(true);
    }
  };

  if (!form) {
    return null;
  }

  function validateFieldByInputType(inputType: string, value: any) {
    switch (inputType) {
      case 'FirstName':
        return validateField(value, {
          required: true,
          firstName: true,
        });
      case 'Surname':
        return validateField(value, {
          required: true,
          lastName: true,
        });
      case 'Building':
        return validateField(value, {
          required: true,
          building: true,
        });
      case 'Street':
        return validateField(value, {
          required: true,
          street: true,
        });
      case 'TownCity':
        return validateField(value, {
          required: true,
          city: true,
        });
      case 'County':
        return validateField(value, {
          required: true,
          county: true,
        });
      case 'PostCode':
        return validateField(value, {
          required: true,
          postCode: true,
        });
      case 'EmailAddress':
      case 'KeyEmailAddress':
        return validateField(value, {
          required: true,
          email: true,
        });
      case 'NINumber':
        return validateField(value, {
          required: true,
          nationalInsurance: true,
        });
      case 'HomePhoneNumber':
        return validateField(value, {
          required: true,
          homeNumber: true,
        });
      case 'MobilePhoneNumber':
        return validateField(value, {
          required: true,
          mobileNumber: true,
        });
      default:
        return validateField(value);
    }
  }

  const fieldErrors = form.formData
    .map(
      (field) =>
        field.itemType === 'input' &&
        (isDataTable(field)
          ? field.props.data
              .flat()
              .map(
                (data) =>
                  new TextInputCell(
                    '',
                    data.value,
                    'Data',
                    !field.props.isOptional,
                  ).error,
              )
              .filter((e) => !!e).length > 0
            ? 'Data table error'
            : ''
          : validateField(field.props.value, field.validation)),
    )
    .concat(
      form.formData
        .filter((f) => f.itemType === 'prepop')
        .map((field) => {
          return validateFieldByInputType(field.inputType, field.props.value);
        }),
    );

  const isValid = fieldErrors.every((str) => !str);

  return (
    <ContentContainer small>
      <FormTitleHeader>
        <h1>{form.formName}</h1>
      </FormTitleHeader>
      <LightContentBox>
        <form>
          <Stack stackMultiplier={3}>
            {submitted && (
              <Message
                success
                text="Thank you for your submission"
                dataQa="form-lbl-submitted"
              />
            )}
            {!submitted && (
              <>
                <RenderFormTemplate
                  formId={formId}
                  form={form}
                  setForm={setForm}
                  userType={'enquiry'}
                  reviewing={false}
                  hasCorrections={false}
                />
                <FixedFooter>
                  <ButtonRowMobile>
                    <Button
                      onClick={() => {
                        ReactGA.event({
                          category: 'Button',
                          action: 'Enquiry form - Submit form',
                        });
                        showModal(ConfirmSubmitForm, { onComplete: onSubmit });
                      }}
                      iconRight={<IconArrowRight responsive />}
                      disabled={!isValid}
                      dataQa="enquiryForm-btn-submit"
                    >
                      Submit Form
                    </Button>
                  </ButtonRowMobile>
                </FixedFooter>
              </>
            )}
          </Stack>
        </form>
      </LightContentBox>
    </ContentContainer>
  );
};

export default FillEnquiry;
