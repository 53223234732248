import { useEffect } from 'react';

export const useDetectWindowClose = (disabled = false) => {
  useEffect(() => {
    const confirmNavigate = (e: BeforeUnloadEvent) => {
      const confirmationMessage = 'Are you sure you want to leave?';
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };

    if (!disabled) {
      window.addEventListener('beforeunload', confirmNavigate);
    }
    return () => window.removeEventListener('beforeunload', confirmNavigate);
  });
};
