import styled from 'styled-components';
import { boxShadow, color } from '../../config';

export const FixedFooterContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: ${color.light};
  padding: 1.6rem;
  box-shadow: ${boxShadow.primary};
`;
