import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FixedFooterContainer } from './FixedFooter.style';

const FixedFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const { height } = ref.current?.getBoundingClientRect() || {};
    document.body.style.paddingBottom = `${height}px`;
    return () => {
      document.body.style.paddingBottom = '0px';
    };
  }, []);
  return createPortal(
    <FixedFooterContainer ref={ref}>{children}</FixedFooterContainer>,
    document.body,
  );
};

export default FixedFooter;
